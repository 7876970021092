@import url('https://fonts.googleapis.com/css?family=Baloo+Chettan+2&display=swap');
body{
  font-family: 'Baloo Chettan 2', cursive !important;
}


.bg{
  background-image:url('./images/bg.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; /* Resize the background image to cover the entire container */
}

.overlay{
  background-color: rgb(0, 0, 0, 0.3);
  top:0;
  padding-top:20px;

}